import React from "react";
import styled from "styled-components";

import IndivRecord from "./indivrecord";

import {
  StyledContainerWrapper,
  StyledRecordingTwoColumns,
  StyledConfTitle,
} from "../styles/StyledEGConf";

import videoImg from "../images/video.svg";
import tanmaiTalk from "../images/tanmai-talk.png";
import ratchelTalk from "../images/ratchel-talk.png";
import shyamalJagannathMyeeTalk from "../images/shyamal-jagannath-myee-talk.png";
import arunTalk from "../images/arun-talk.png";
import zhamakTanmaiTalk from "../images/zhamak-tanmai-talk.png";

const mainRecording = [
  {
    talkType: "KEYNOTE",
    title: "Build a GraphQL powered data mesh for your polyglot data",
    linkUrl: "/enterprisegraphql/2021/build-a-graphql-powered-data-mesh-for-your-polyglot-data/",
    recordThumb: tanmaiTalk,
    bigArrow: true,
  },
];

const otherRecording = [
  {
    talkType: "FIRESIDE CHAT",
    title: "Fireside Chat: GraphQL & the Data Mesh",
    linkUrl: "/enterprisegraphql/2021/graphql-the-data-mesh/",
    recordThumb: zhamakTanmaiTalk,
  },
  {
    talkType: "TALK",
    title: "The Ghosts of Databases Past, Present and Future",
    linkUrl: "/enterprisegraphql/2021/the-ghosts-of-databases-past-present-and-future/",
    recordThumb: ratchelTalk,
  },
  {
    talkType: "TALK",
    title: "Improving quality of life for Aussie farmers",
    linkUrl: "/enterprisegraphql/2021/improving-quality-of-life-for-aussie-farmers/",
    recordThumb: shyamalJagannathMyeeTalk,
  },
  {
    talkType: "TALK",
    title: "GraphQL, the gateway to a New Service Paradigm",
    linkUrl: "/enterprisegraphql/2021/graphql-the-gateway-to-a-new-service-paradigm/",
    recordThumb: arunTalk,
  },
];

const StyledRecordingConf = styled.div`
  padding-top: 80px;
  .recordingHeader {
    display: flex;
    align-items: center;
    img {
      margin-right: 22px;
    }
  }
`;

const RecordingConf = () => {
  const mainRecordingConf = mainRecording.map((recordItem, index) => (
    <IndivRecord key={index} recordings={recordItem} />
  ));
  const otherRecordingConf = otherRecording.map((recordItem, index) => (
    <IndivRecord key={index} recordings={recordItem} />
  ));
  return (
    <StyledContainerWrapper>
      <StyledRecordingConf>
        <StyledConfTitle className="recordingHeader" pb="32px" blueColor="#1B2738">
          <img src={videoImg} alt="Video" />
          Recordings
        </StyledConfTitle>
        {mainRecordingConf}
        <StyledRecordingTwoColumns>{otherRecordingConf}</StyledRecordingTwoColumns>
      </StyledRecordingConf>
    </StyledContainerWrapper>
  );
};

export default RecordingConf;
