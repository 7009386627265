import React from "react";
import { Link } from "gatsby";

import {
  StyledPatternPos,
  StyledContainerWrapper,
  StyledTopBanner,
  StyledConfDescTitle,
  StyledConfSubTitle,
  StyledBlueButton,
  StyledBlinkingDiv,
} from "../styles/StyledEGConf";

import egcLogo from "../images/egc-logo-2021.svg";

const TopBanner = ({ location, homepagePromo }) => {
  return (
    <StyledPatternPos>
      <StyledContainerWrapper>
        <StyledTopBanner id="egc-register-form">
          <div className="alignSelfCenter">
            {homepagePromo ? (
              <div className="egcBrand">
                <Link to="/enterprisegraphql/2021/">
                  <img src={egcLogo} alt="Brand" />
                </Link>
              </div>
            ) : null}
            <StyledBlinkingDiv>
              <div className="circle green" />
              <StyledConfDescTitle>Recordings are available</StyledConfDescTitle>
            </StyledBlinkingDiv>
            <h1 className="confMainTitle">GraphQL and The Data Mesh</h1>
            {homepagePromo ? (
              <div className="buttonWrapper">
                <Link to="/enterprisegraphql/2021/">
                  <StyledBlueButton>Read more</StyledBlueButton>
                </Link>
              </div>
            ) : null}
          </div>
          <div className="alignSelfEnd">
            <StyledConfSubTitle>
              Explore how organisations are leveraging GraphQL to federate data while transforming
              developer experience & productivity.
            </StyledConfSubTitle>
          </div>
        </StyledTopBanner>
      </StyledContainerWrapper>
    </StyledPatternPos>
  );
};

export default TopBanner;
