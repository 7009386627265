import React from "react";
import Seo from "../../components/seo";
// import { StyledMobileFixButton } from "../components/enterpriseConf2021/styles/StyledEGConf";
import Layout from "../../components/enterpriseConf2021/layout";
import TopBanner from "../../components/enterpriseConf2021/homepage/topbanner";
import RecordingConf from "../../components/enterpriseConf2021/homepage/recordingconf";
import AboutConf from "../../components/enterpriseConf2021/homepage/aboutconf"
import CheckoutVideo from "../../components/enterpriseConf2021/homepage/checkoutvideo";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/enterprise-conf-2021.png",
};
const canonicalUrl = "https://hasura.io/enterprisegraphql/2021/";
const EnterpriseConf2021 = props => (
  <Layout location={props.location}>
    <Seo
      title="Enterprise GraphQL Conference | GraphQL and the Data Mesh"
      description="Explore the concept and the best practices in building, operating, and maintaining an enterprise-grade data mesh powered by GraphQL."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner location={props.location}/>
    <RecordingConf />
    <AboutConf />
    <CheckoutVideo />
  </Layout>
);

export default EnterpriseConf2021;
