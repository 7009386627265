import React from "react";

import {
  StyledContainerWrapper,
  StyledAboutConf,
  StyledConfSubTitle,
  StyledConfDesc,
} from "../styles/StyledEGConf";

// import optum from "../images/optum.png";
import airbnb from "../images/airbnb.png";
import uber from "../images/uber.png";
import shopify from "../images/shopify.png";
import graphqlMiniConference from "../images/graphql-mini-conference.png";

const AboutConf = () => {
  return (
    <StyledContainerWrapper>
      <StyledAboutConf id="about">
        <div className="aboutConfWrapper">
          <div>
            <StyledConfSubTitle blueColor="#1B2738">
              Explore the Data Mesh via GraphQL
            </StyledConfSubTitle>
            <StyledConfDesc>
              GraphQL is being used to simplify operational & analytical data challenges in
              organisations. Different approaches such as building an operational data lake with an
              easy data access API, as well as a federated data access API are being explored and
              GraphQL opens up opportunities for enabling these architectures by laying the
              foundation for a data mesh. In this conference, we will explore the concept and the
              best-practices in building, operating and maintaining an enterprise grade data mesh
              powered by GraphQL.
            </StyledConfDesc>
            <br />
          </div>
          <div>
            <div className="aboutConfImg">
              <img src={graphqlMiniConference} alt="about conf" />
            </div>
          </div>
        </div>
        <div className="companiesSpokeWrapper">
          <div className="companiesDesc">Companies Who spoke At egc’20</div>
          <div className="companiesBrand">
            {/* <div className="companiesBrandImg">
              <img src={optum} alt="Optum" />
            </div> */}
            <div className="companiesBrandImg">
              <img src={airbnb} alt="AirBNB" />
            </div>
            <div className="companiesBrandImg">
              <img src={uber} alt="Uber" />
            </div>
            <div className="companiesBrandImg">
              <img src={shopify} alt="Shopify" />
            </div>
          </div>
        </div>
        {/*
        <div className="tabHeader" id="talks">
          <div className="flexOne">
            <StyledConfTitle blueColor="#1B2738" pb="10px">Conference Schedule</StyledConfTitle>
            <StyledConfDescSmall blueColor="#1B2738">Times below are shown in your local browser time zone.</StyledConfDescSmall>
          </div>
          <div className="buttonWrapper">
            <a href="#egc-register-form">
              <StyledGreenButton>Register now</StyledGreenButton>
            </a>
          </div>
        </div>
        <div className="cardWrapper">
          {talk}
        </div>
        */}
      </StyledAboutConf>
    </StyledContainerWrapper>
  );
};

export default AboutConf;
